/* eslint-disable react-hooks/exhaustive-deps */
import parse from 'html-react-parser';
import { lazy, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { useLocation, useParams } from 'react-router-dom';
import { EmailShareButton, FacebookIcon, FacebookShareButton } from 'react-share';
import Bookmark from '../../../components/BookMark';
import ImageListModel from '../../../components/ImageListModel';
import { ReviewsConfigContext } from '../../../components/Ratings';
import { useUser } from '../../../lib/auth';
import { currentApi } from '../../../services/Db/dbInfo';
import { TripDetails } from '../../../services/Guide/guide';
import { getPrice, getSession } from '../../../services/utils';
import storage from '../../../utils/storage';
import BoatInfoModel from '../../Guides/components/Boats/boatInfoModel';
import BookingRequestModel from './BookingRequestModel';
import GuideInfoCard from './GuideInfoCard';
import { useGetGuideById } from '../../../services/Search/searchService';
import { getLanguageSpoken } from '../../../services/languages';
import i18next from 'i18next';
const MapView = lazy(() => import('../../../components/MapView'));

function TripInfo({ trip, tempCoverUrl }: { trip: TripDetails, tempCoverUrl?: string }) {
  const { t } = useTranslation();
  const { bookingRequest } = useParams();
  const [scrolled, setScrolled] = useState(false);
  const [boatInfo, setBoatInfo] = useState<{
    show: boolean;
    id?: number;
  }>({ show: false });
  const [showBookingRequest, setBookingRequest] = useState(
    bookingRequest?.toString() === trip?.id?.toString() || false
  );

  const { data: userInfo, isLoading: isLoadingUser } = useUser();
  const { data: guide } = useGetGuideById(trip?.guide?.id);
  const handelBookingRequest = () => {
    setBookingRequest(true);
  };

  const images: ReactImageGalleryItem[] =
    trip?.tripMedia?.map((media: any) => {
      return { original: `${currentApi.fileBasePath}${media?.url}` };
    }) || [];

  const [showDialog, setShowDialog] = useState(false);
  const { state } = useLocation();
  const { currentPlace } = state || {};

  const { setContentID, setCanPostReview, setUser } = useContext(ReviewsConfigContext);
  useEffect(() => {
    if (trip.id) {
      setContentID(trip.id);
      setCanPostReview(true);
    }
  }, [trip.id]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (userInfo) {
      const token = storage.getToken();
      setUser({
        id: Number(userInfo.id),
        token: token,
        username: userInfo.username,
        email: userInfo.username,
      });
    }
  }, [isLoadingUser]);

  const [backgroundImage, setBackgroundImage] = useState(
    `url("${currentApi.fileBasePath}${trip?.guide?.showTempMedia ? tempCoverUrl : trip?.guide?.businessMedia?.url}")`
  );
  useEffect(() => {
    const errorBackgroundImage = 'url("/img/image.jpg")';
    const img = new Image();
    const url = `${currentApi.fileBasePath}${trip?.guide?.showTempMedia ? tempCoverUrl : trip?.guide?.businessMedia?.url}`;
    img.onload = () => {
      setBackgroundImage(`url("${url}")`);
    };
    img.onerror = () => {
      setBackgroundImage(errorBackgroundImage);
    };
    img.src = url;
  }, [trip?.guide?.businessMedia?.url]);  

  return (
    <>
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: backgroundImage }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            {/* <span className="h6">
              {trip?.city}, {getCountry(trip?.country, i18n.language)}
            </span> */}
            <h1 className="display_operator_name">{trip?.name}</h1>

            <div id="wrapper">
              <input type="checkbox" className="checkbox" id="share" />
              <label htmlFor="share" title="SHARE" className="label entypo-export">
                <i className="fa fa-share" aria-hidden="true"></i> {t('common.share')}
              </label>
              <Bookmark tripId={Number(trip.id)} />
              <div className="social">
                <ul>
                  <li className="entypo-twitter">
                    <EmailShareButton
                      url={window.location.href}
                      subject={trip.name}
                      body={trip.description}
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </EmailShareButton>
                  </li>
                  <li className="entypo-facebook">
                    <FacebookShareButton
                      url={window.location.href}
                      quote={trip.name}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={30} round />
                    </FacebookShareButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ backgroundColor: '#FFF', marginTop: '-90px' }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-capitalize">
            <div
              className="display_operator_info pb-3 mx-3 d-flex justify-content-between align-item-center"
              style={{ borderBottom: '1px solid #EEEEEE', flexWrap: 'wrap' }}
            >
              <h4 style={{ lineHeight: 'unset' }}>{trip.name}</h4>
              <del>
                <span>
                {getPrice(
                  t,
                  trip?.isSharedTrip,
                  trip?.isSharedTrip === true ? trip?.pricePerPerson : trip?.priceifNotShared,
                  trip.guide_boat ? true : false
                )}{' '}
                &nbsp;{' '}
                </span>
                <button
                  type="button"
                  onClick={handelBookingRequest}
                  className={`btn btn-gold btn-sm center-block booking-btn ${scrolled ? 'scrolled' : ''}`}
                >
                  {t('trip.send_booking_request')}
                </button>
              </del>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 px-4">
            <div className="display_operator_info">
              {trip?.description && parse(trip.description)}
            </div>
            <h4 className="display_images-header text-center">{t('trip.trip_images')}</h4>
            <div className="display_operator_photo shadow-sm mt-3">
              <div className="photo-gallery">
                <div className="row photos">
                  {showDialog && (
                    <ImageListModel show={showDialog} images={images} onClose={setShowDialog} />
                  )}

                  {trip?.tripMedia &&
                    trip?.tripMedia.map((bm, index) => (
                      <div
                        key={`tripMedia_${index}`}
                        className="col-lg-4 col-md-6 col-sm-6 col-6 mb-4 item"
                      >
                        <span
                          onClick={() => {
                            setShowDialog(true);
                          }}
                        >
                          <img
                            style={{ height: '170px', objectFit: 'cover', width: '100%' }}
                            className="img-fluid cursor-pointer"
                            alt="Trip media"
                            src={`${currentApi.fileBasePath}${bm.url}`}
                          />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <h4 className="display_images-header text-center">{t('trip.trip_specifics')}</h4>
            <div className="display_operator_photo shadow-sm mt-3 mb-4">
              <div className="mt-2 mb-3">
                <h6>{t('trip.fishing')}</h6>
                <small>{t('trip.fields.targetedSpecies.label')}:</small>{' '}
                <small className="text-muted">
                  {trip?.targetedSpecies
                    ?.map((item: any) => t(`master_data.fishing-specie.${item.value}`))
                    ?.join(', ')}
                </small>
                <br />
                <small>{t('trip.fields.fishingTechniques.label')}:</small>{' '}
                <small className="text-muted">
                  {trip?.fishingTechniques
                    ?.map((item: any) => t(`master_data.fishing-technique.${item.value}`))
                    ?.join(', ')}
                </small>
              </div>
              <div className="mt-2 mb-3">
                <h6>{t('trip.trip_info')}</h6>
                <small>{t('trip.fields.fishingTypes.label')}:</small>{' '}
                <small className="text-muted">
                  {trip?.fishingTypes
                    ?.map((item: any) => t(`master_data.fishing-type.${item.value}`))
                    ?.join(', ')}{' '}
                </small>
                <br />
                <small>{t('trip.fields.duration.label')}:</small>{' '}
                <small className="text-muted">
                  {trip.duration} {t('common.hours')}
                </small>
                <br />
                <small>{t('trip.fields.departure.label')}:</small>{' '}
                <small className="text-muted">
                  {trip.departure} {t('common.am')}
                </small>
                <br />
                <small>{t('trip.meeting_point')}:</small>{' '}
                <small className="text-muted">{trip.streetAddress}</small> <br />
                <small>{t('trip.fields.pickupPolicy.label')}:</small>{' '}
                <small className="text-muted">
                  {t(`master_data.pickup-policy.${trip?.pickupPolicy?.value}`)}
                </small>
                <br />
                {trip.maxAmountofPeople && (
                  <>
                    <small>{t('boat.fields.guestCapacity.label')}:</small>
                    <small className="text-muted">
                      {trip.maxAmountofPeople} {t('common.guests')}
                    </small>
                    <br />
                  </>
                )}
                {typeof trip.minAmountofPeople === 'number' &&  trip.minAmountofPeople > 0 && (
                  <>
                    <small>{t('trip.fields.minAmountofPeople.label')}:</small>
                    <small className="text-muted">
                      {trip.minAmountofPeople} {t('common.guests')}
                    </small>
                    <br />
                  </>
                )}
                <small>{t('trip.fields.minimumAge.label')}:</small>
                <small className="text-muted">
                  {trip.minimumAge} {t('common.years')}
                </small>
              </div>
              <div className="mt-2 mb-3">
                <h6>{t('common.session_availibility')}</h6>
                <small className="text-muted">
                  {getSession(
                    t,
                    trip?.operateDays ? trip?.operateDays : [],
                    trip?.isSeasonalTrip,
                    trip?.season
                  )}
                </small>
              </div>
              <div className="mt-2 mb-3">
                <h6>{t('common.insurance_certificate')}</h6>
                <small className="text-muted">
                  {guide?.businessProfile?.certificates ? 'Yes': 'No'}
                </small>
              </div>
              <div className="mt-2 mb-3">
                <h6>{t('common.license_certificate')}</h6>
                <small className="text-muted">
                {guide?.businessProfile?.licensesCertificates ? 'Yes': 'No'}
                </small>
              </div>
              <div className="mt-2 mb-3">
                <h6>{t('trip.trip_features')}</h6>
                <small>{t('trip.fields.isNecessaryGearIncluded.label1')}:</small>
                <small className="text-muted">
                  {trip.isNecessaryGearIncluded ? t('common.included') : t('common.not_included')}
                </small>
                <br />
                <small>{t('trip.fields.isFishingLicenseIncluded.label1')}:</small>
                <small className="text-muted">
                  {trip.isFishingLicenseIncluded ? t('common.included') : t('common.not_included')}
                </small>
                <br />
                <small>{t('trip.fields.catchPolicy.label')}:</small>
                <small className="text-muted">
                  {trip?.catchPolicy
                    ?.map((item: any) => t(`master_data.catch-policy.${item.value}`))
                    ?.join(', ')}
                </small>
                <br />
                <small>{t('trip.fields.foodAndBeverages.label1')}:</small>
                <small className="text-muted">
                  {trip?.foodAndBeverages
                    ?.map((item: any) => t(`master_data.food-and-beverage.${item.value}`))
                    ?.join(', ')}
                </small>
                <br />
                <small>{t('trip.fields.alcoholAllowed.label1')}:</small>
                <small className="text-muted">
                  {trip.alcoholAllowed ? t('common.allowed') : t('common.not_allowed')}
                </small>
              </div>
              {trip.guide_boat && trip.guide_boat.id && (
                <div className="mt-2 mb-3">
                  <h6> {t('boat.boat_facilities_equipment')}</h6>
                  <small>{t('boat.fields.boatType.label')}:</small>
                  <small className="text-muted">
                    {t(`master_data.boat-type.${trip?.guide_boat?.boatType?.value}`)}
                    <button
                      onClick={() => setBoatInfo({ show: true, id: Number(trip?.guide_boat?.id) })}
                      className="link-text-white btn btn-link btn-sm"
                    >
                      {t('common.detailed_info')}
                    </button>
                  </small>
                  <br />
                  <small>{t('boat.fields.boatLength.label')}: </small>{' '}
                  <small className="text-muted">{trip?.guide_boat?.boatLength} ft</small> <br />
                  <small> {t('boat.fields.boatManufacturer.label')}: </small>{' '}
                  <small className="text-muted">{trip?.guide_boat?.boatManufacturer}</small> <br />
                </div>
              )}
              <div className="mt-2 mb-3">
                <h6>{t('trip.trip_cancellation_policy')}</h6>
                <small>{t('trip.fields.cancellationPolicy.label')}:</small>
                <small className="text-muted">
                  {trip?.cancellationPolicyObject?.id
                    ? `${trip?.cancellationPolicyObject?.name}`
                    : ''}
                </small>
                <br />
                {trip?.cancellationPolicyObject?.id === 2 ? (
                <>
                <small>{t('trip.fields.freeCancellation.label')}:</small>{' '}
                <small className="text-muted">
                  {trip?.freeCancellation} {t('common.days')}{' '}
                </small>
                </>): null}
                <br />
              </div>
            </div>
            <button
              type="button"
              onClick={handelBookingRequest}
              className="btn btn-gold btn-sm center-block d-none d-md-inline-block"
            >
              {t('trip.send_booking_request')}
            </button>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="ratings-widget display_operator_shortinfo">
              <div
                id="map-container-google-9"
                className="z-depth-1-half map-container-5"
                style={{ height: '420px' }}
              >
                <MapView
                  mapLocation={currentPlace}
                  containerStyle={{
                    height: '100%',
                    width: '100%',
                  }}
                  markers={[{ lat: trip.lat, long: trip.long }]}
                />
              </div>
            </div>
            <GuideInfoCard guideId={trip?.guide?.id} tripId={Number(trip?.id)} />
          </div>
        </div>
      </div>
      {boatInfo.show && (
        <BoatInfoModel
          show={boatInfo.show}
          id={Number(boatInfo?.id)}
          onClose={() => setBoatInfo({ show: false })}
        />
      )}

      {showBookingRequest && (
        <BookingRequestModel
          show={showBookingRequest}
          id={Number(trip?.id)}
          recipient={`${trip?.guide?.businessName} ${trip.name}`}
          languages={guide?.guideProfile?.languagesSpoken ? getLanguageSpoken(guide.guideProfile.languagesSpoken, i18next.language) : ''}
          onClose={() => setBookingRequest(false)}
        />
      )}
    </>
  );
}

export default TripInfo;
