import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginRedirect from './components/AuthCallback/LoginRedirect';
import AdminLayout from './components/Layout/Admin';
import Layout from './components/Layout/Home';
import { authBroadcastChannel } from './lib/auth';
import { Auth } from './lib/authorization';
import NotFound from './screens/Auth/NotFound';
import Unauthorized from './screens/Auth/Unauthorized';
import Boats from './screens/Boats';
import CreateUpdateBoat from './screens/Boats/CreateUpdateBoat';
import Dashboard from './screens/Dashboard';
import EmailPreferences from './screens/EmailPreferences';
import FAQ from './screens/FAQ';
import Favorites from './screens/Favorites';
import ForgotPassword from './screens/ForgotPassword';
import GuideSearch from './screens/GuideSearch';
import Guides from './screens/Guides';
import Home from './screens/Home';
import InfoPage from './screens/InfoPage';
import Messages from './screens/Messages';
import MyListing from './screens/MyListing';
import News from './screens/News';
import NewsView from './screens/News/view';
import Privacy from './screens/Privacy';
import Profile from './screens/Profile';
import Register from './screens/Register';
import ResetPassword from './screens/ResetPassword';
import Setting from './screens/Setting';
import SignIn from './screens/SignIn';
import Terms from './screens/Terms';
import Trips from './screens/Trips';
import CreateUpdateTrip from './screens/Trips/CreateUpdateTrip';
import PaymentSuccess from './screens/PaymentSuccess';
import SubscriptionSelection from './screens/SubscriptionSelection';
import PaymentCancel from './screens/PaymentCancel';
import ChronicleView from './screens/News/chronicle';
import About from './screens/About';
import ErrorBoundary from './components/ErrorBoundary';
import Contact from './screens/Contact';

const App: React.FC = () => {
  useEffect(() => {
    authBroadcastChannel();
  }, []);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/register-guide" element={<Register isGuide={true} />} />
        <Route
          path="/search/:placeId/:long/:lat/:minLng/:minLat/:maxLng/:maxLat/:noofpassanger/:countryCode/:city?"
          element={<ErrorBoundary fallback={<NotFound />}><GuideSearch /></ErrorBoundary>}
        />
        <Route path="/trip-info/:tripId/:bookingRequest?" element={<ErrorBoundary fallback={<NotFound />}><InfoPage /></ErrorBoundary>} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq/:type?" element={<FAQ />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="/emailpreferences/:ivHex/:encryptedData" element={<EmailPreferences />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<ErrorBoundary fallback={<NotFound />}><NewsView /></ErrorBoundary>} />
        <Route path="/chronicle/:id" element={<ErrorBoundary fallback={<NotFound />}><ChronicleView /></ErrorBoundary>} />

        <Route element={<Auth allowedRoles={['guide', 'user']} />}>
          <Route path="/guides" element={<Guides />} />
          <Route path='/payment-success' element={<PaymentSuccess />} />
          <Route path='/payment-cancel' element={<PaymentCancel />} />
          <Route path='/select-subscription' element={<SubscriptionSelection />} />
        </Route>

        <Route element={<Auth allowedRoles={[]} />}>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/connect/:providerName/redirect" element={<ErrorBoundary fallback={<NotFound />}><LoginRedirect /></ErrorBoundary>} />
        </Route>
      </Route>

      <Route element={<AdminLayout />}>
        <Route element={<Auth allowedRoles={['guide', 'user']} />}>
          <Route path="/dashboard/:guideId?" element={<ErrorBoundary fallback={<NotFound />}><Dashboard /></ErrorBoundary>} />
          <Route path="/messages/:conversationId?/:guideId?" element={<ErrorBoundary fallback={<NotFound />}><Messages /></ErrorBoundary>} />
          <Route path="/Setting" element={<Setting />} />
          <Route path="/Favorites" element={<Favorites />} />
        </Route>
        <Route element={<Auth allowedRoles={['guide']} />}>
          <Route path="/listing" element={<MyListing />} />
          <Route path="/trip" element={<Trips />} />
          <Route path="/trip/create" element={<CreateUpdateTrip />} />
          <Route path="/trip/create/:id?" element={<ErrorBoundary fallback={<NotFound />}><CreateUpdateTrip edit={false} /></ErrorBoundary>} />
          <Route path="/trip/update/:id" element={<ErrorBoundary fallback={<NotFound />}><CreateUpdateTrip edit={true} /></ErrorBoundary>} />
          <Route path="/boats" element={<Boats />} />
          <Route path="/boats/create" element={<CreateUpdateBoat />} />
          <Route path="/boats/update/:id" element={<ErrorBoundary fallback={<NotFound />}><CreateUpdateBoat edit={true} /></ErrorBoundary>} />
          <Route path="/Profile" element={<Profile />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
